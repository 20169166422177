.site-header__wrapper {
	justify-content: space-between;
	min-height: 144px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
}

@media (width >= 1200px) {
	.is-shifted.site-header__wrapper {
		transition: transform .2s, opacity .2s;
		transform: translateY(-100%);
		opacity: 0;
	}

	.is-shifted.is-fixed.site-header__wrapper {
		transform: translateY(0);
		opacity: 1;
	}

	.is-fixed.site-header__wrapper {
		background-color: #FFF;
		position: fixed;
		min-height: auto;
		width: 100%;
		box-shadow: 0 4px 18px rgba(105, 100, 99, .14);
		padding-left: 50px;
		padding-right: 50px;
		z-index: 10;
	}
}

.site-header__menu {
	height: 70px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-basis: calc(100% - 600px);
	margin-right: 0;
	background-color: white;
	box-shadow: 0 4px 18px rgba(105, 100, 99, 0.14);
	position: relative;
}

@media (width <= 1799px) {
	.site-header__menu {
		height: auto;
		flex-basis: calc(100% - 650px);
		flex-wrap: wrap;
		box-shadow: none;
		margin-top: 15px;
	}
}

@media (width <= 1399px) {
	.site-header__menu {
		flex-basis: calc(100% - 550px);
	}
}

@media (width <= 1199px) {
	.site-header__menu {
		flex-basis: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}	
}

@media (width >= 1400px) {
	.site-header__menu {
		margin-right: 100px;
	}
}

.site-header.extra-space  {
	margin-bottom: 154px;
}