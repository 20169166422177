.index--about {
	padding-top: 40px;
	padding-bottom: 40px;
}

.index--about__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media (width <= 991px) {
	.index--about__wrapper {
		justify-content: center;
	}
}

.index--about__text {
	flex-basis: 30%;
}

@media (width <= 991px) {
	.index--about__text {
		flex-basis: 100%;
	}
}

.index--about h1,
.index--about h2 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	text-align: left;
	padding-bottom: 40px;
	margin-bottom: 40px;
	position: relative;
}

.index--about h1::after,
.index--about h2::after {
	position: absolute;
	content:"";
	bottom: 0;
	left: 0;
	width: 60px;
	height: 2px;
	background-color: #ddae52;
	transition: width 10s;
}

.index--about:hover h1::after,
.index--about:hover h2::after {
	width: 100%;
}

.index--about p {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
}

.index--about__icons {
	flex-basis: 65%;
	max-width: 65%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 80px;
}

@media (width <= 991px) {
	.index--about__icons {
		flex-basis: 100%;
		max-width: 100%;
		margin-top: 40px;
	}
}

.index--about__icons p {
	flex-basis: 50%;
	max-width: 50%;
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 20px;
}

@media (width <= 991px) {
	.index--about__icons p {
		padding-left: 0;
		padding-right: 20px;
	}
}

@media (width <= 641px) {
	.index--about__icons p {
		flex-basis: 100%;
		max-width: 100%;
		padding-right: 0;
	}
}

.index--about__icons i {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-basis: 90px;
	width: 90px;
	height: 90px;
	background-color: #f6f6f6;
}

.index--about__icons p:hover .aboutDark {
	animation: about-blink .4s linear;
}

.index--about__icons p:hover .aboutGold {
	animation: about-blink .6s linear;
}

.index--about__icons svg {
	display: block;
}

.index--about__icons span {
	color: #0b0c0f;
	@apply --r4;
	font-size: 18px;
	padding-left: 15px;
	flex-basis: calc(100% - 90px);
	max-width: calc(100% - 90px);
}

.aboutGold { fill: #c49f53; }
.aboutDark { fill: #45474e; }
.aboutNone { fill: none; }
.aboutWhite { fill: white; }

@keyframes about-blink {
	0% {opacity: 0}
	70% {opacity: 0}
	100% {opacity: 1}
}