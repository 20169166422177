.site-footer__menu {
	display: flex;
	flex-basis: calc(100% - 600px);
	max-width: calc(100% - 600px);
}

.site-footer__menu-list {
	margin-right: 40px;
	flex-basis: 30%;
	max-width: 30%;
}

@media (width <= 1799px) {
	.site-footer__menu {
		flex-basis: calc(100% - 440px);
		justify-content: space-between;
		max-width: 700px;
	}

	.site-footer__menu-list {
		margin-right: 20px;
		flex-basis: auto;
	}
}

@media (width <= 1199px) {
	.site-footer__menu {
		flex-basis: calc(100% - 320px);
		max-width: calc(100% - 320px);
	}

	.site-footer__menu-list {
		margin-right: 0;
	}
}

@media (width <= 991px) {
	.site-footer__menu {
		flex-basis: 100%;
		max-width: 100%;
		flex-wrap: wrap;
	}
}

.site-footer__menu-list h3 {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	text-transform: uppercase;
	margin-bottom: 25px;
	margin-top: 0;
}

@media (width <= 991px) {
	.site-footer__menu {
		max-width: none;
	}
	.site-footer__menu-list {
		flex-basis: 100%;
		max-width: 100%;
		margin-right: 0;
	}

	.site-footer__menu-list:first-child {
		margin-bottom: 20px;
	}

	.site-footer__menu-list h3 {
		display: none;
	}
}

.site-footer__menu-list ul {
	padding-left: 0;
	list-style: none;
}

@media (width <= 991px) {
	.site-footer__menu-list ul {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;
	}
}

.site-footer__menu-list li {
	margin-bottom: 10px;
}

@media (width <= 991px) {
	.site-footer__menu-list li {
		flex-basis: 50%;
		max-width: 50%;
	}
}

@media (width <= 641px) {
	.site-footer__menu-list li {
		flex-basis: 100%;
		max-width: 100%;
		text-align: center;	
	}
}

.site-footer__menu-list a {
	color: #c5a054;
	@apply --r5;
	font-size: 14px;
}

@media (width <= 1199px) {
	.site-footer__menu-list h3 {
		font-size: 20px;
	}

	.site-footer__menu-list a {
		font-size: 20px;
		font-weight: 400;
	}
}

.site-footer__menu-list a:hover {
	@apply --transitionOpacityEnd;
}