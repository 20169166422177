.photo-slider {
	width: 100%;
	margin-top: 30px;
}

.photo-slider h2 {
	color: #25262a;
	@apply --r5;
	font-size: 32px;
	text-align: center;
	margin-bottom: 20px;
}

.photo-slider__wrapper {
	position: relative;
}

.photo-slider__photo a {
	@apply --transitionOpacityStart;
}

.photo-slider__photo a:hover {
	@apply --transitionOpacityEnd;
}

.photo-slider__photo a img {
	width: 100%;
	height: auto;
}

.photo-slider__nav {
	position: absolute;
	width: 100%;
	height: 0;
	left: 0;
	top: calc(50% - 50px);
}

.photo-slider__nav span {
	width: 55px;
	height: 99px;
	display: block;
	position: absolute;
	top: 0;
	cursor: pointer;
	will-change: opacity;
	opacity: 0.7;
	transition: opacity .3s;
}

.photo-slider__nav span:hover {
	opacity: 1;
}

.photo-slider__nav span svg {
	width: 100%;
	height: 100%;
}

.photo-slider__nav .pageFill {
	fill: white;
}

.photo-slider__nav span:hover .pageFill {
	fill: #ddae52;
} 

.photo-slider__nav--left {
	left: 10px;
}

.photo-slider__nav--right {
	right: 10px;
	transform: rotate(180deg);
}