.card--sales {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-basis: calc(33% - 20px);
	max-width: calc(33% - 20px);
	background-color: #FFF;
	@apply --boxShadow;
	margin-bottom: 30px;
	padding: 40px 20px;
	position: relative;
	overflow: hidden;
}

.card--sales::after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: calc(100% + 1px);
	height: 100%;
	background-color: rgba(197,160,84,.7);
	will-change: opacity;
	opacity: 0;
	transition: opacity .3s;
	z-index: 0;
}

.card--sales:hover::after {
	opacity: 1;
}

@media (width <= 991px) {
	.card--sales {
		flex-basis: calc(50% - 15px);
		max-width: calc(50% - 15px);
	}
}

@media (width <= 767px) {
	.card--sales {
		flex-basis: 290px;
		max-width: 290px;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.card--sales:hover {
	@apply --boxShadowHover;	
}

.card--sales > img {
	will-change: opacity;
	opacity: 0;
	transition: opacity .3s;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.card--sales:hover > img {
	opacity: 1;
}

.card--sales__main-link,
.card--sales__sub-links {
	position: relative;
	z-index: 2;
}

.card--sales .card--sales__main-link a {
	display: block;
	width: 100%;
	overflow: hidden;
	@apply --transitionOpacityStart;
}

.card--sales .card--sales__main-link a:hover {
	@apply --transitionOpacityEnd;
}

.card--sales .card--sales__main-link svg {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50px;
	height: 40px;
}

.card--sales .catfill {
	fill: #565656;
	transition: fill .3s;
}

.card--sales:hover .catfill {
	fill: white;
}

.card--sales__main-link h3 {
	text-align: center;
	color: #0b0c0f;
	transition: color .3s;
	@apply --r7;
	font-size: 18px;
	text-transform: uppercase;
	position: relative;
	padding-bottom: 25px;
}

.card--sales:hover .card--sales__main-link h3 {
	color: white;
}

.card--sales__main-link h3::after {
	position: absolute;
	content: "";
	bottom: 0;
	left: calc(50% - 30px);
	width: 60px;
	height: 2px;
	background-color: #c5a054;
	transition: background-color .3s;
}

.card--sales:hover .card--sales__main-link h3::after {
	animation: parabola 10s linear;
}

@keyframes parabola {
	0% { transform: scaleX(0) }
	50% { transform: scaleX(.8) }
	75% { transform: scaleX(.9) }
	100% { transform: scaleX(1) }
}

.card--sales:hover .card--sales__main-link h3::after {
	background-color: white;
}



.card--sales .card--sales__sub-links {
	display: block;
	margin-top: 25px;
	max-width: 100%;
}

.card--sales .card--sales__sub-links a {
	display: block;
	color: #0b0c0f;
	transition: color .3s;
	@apply --r5;
	font-size: 16px;
	margin-bottom: 15px;
	text-align: center;
}

.card--sales:hover .card--sales__sub-links a {
	color: white;
}

.card--sales:hover .card--sales__sub-links a:hover {
	color: #E5E5E5;
}