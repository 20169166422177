
.site-header__slogan {
	padding: 25px 20px;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	flex-basis: 240px;
	max-width: 240px;
	margin-right: auto;
}

.site-header__slogan p {
	margin-bottom: 0;
	@apply --r3;
	color: #525663;
	font-size: 16px;
	max-width: 400px;
}


@media (width <= 1199px) {
	.site-header__slogan {
		flex-basis: 50%;
		max-width: 50%;
	}
}

@media (width <= 767px) {
	.site-header__slogan {
		flex-basis: 100%;
		max-width: 100%;
		padding-bottom: 10px;
	}

	.site-header__slogan p {
		font-size: 20px;
		text-align: center;
	}
}

@media (width >= 1200px) {
	.is-fixed .site-header__slogan {
		display: none;
	}
}