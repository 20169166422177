.card--photo__slider {
	flex-basis: 33%;
	max-width: 33%;
}

@media (width <= 1199px) {
	.card--photo__slider {
		flex-basis: 50%;
		max-width: 50%;
	}	
}

@media (width <= 767px) {
	.card--photo__slider {
		flex-basis: 100%;
		max-width: 490px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 50px;
	}	
}

.card--photo__slider .carousel {
	width: 100%;
}

.card--photo__slider .carousel-inner {
	width: 100%;
	margin-bottom: 15px;
}

.card--photo__slider .carousel-inner img {
	width: 100%;
}

.card--photo__slider .carousel-indicators {
	display: flex;
	justify-content: space-between;
	position: static;
	margin-left: 0;
	width: 100%;
}

@media (width <= 479px) {
	.card--photo__slider .carousel-indicators {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.card--photo__slider .carousel-ind-inst {
	flex-basis: calc((100% - 50px) / 3);
	max-width: calc((100% - 50px) / 3);
	padding-right: 15px;
	background-color: transparent;
}

.card--photo__slider .carousel-ind-inst.active {
	height: 100%;
}

.card--photo__slider .carousel-ind-inst img {
	width: 100%;
	height: auto;
	cursor: pointer;
	@apply --transitionOpacityStart;
}

.card--photo__slider .carousel-ind-inst img:hover {
	@apply --transitionOpacityEnd;	
}

.card--photo__slider .carousel-controls {
	flex-basis: 50px;
	max-width: 50px;
	width: 50px;
	min-height: 87px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.card--photo__slider .carousel-controls a {
	width: 50px;
	height: calc((100% - 15px) / 2);
	min-height: 34px;
	border: 1px solid #c5a054;
	background-color: transparent;
	transition: background-color .3s;
	display: flex;
	/* justify-content: center; */
	/* align-items: center; */
}

.card--photo__slider .carousel-controls a svg {
	margin: auto;
	display: block;
}

.card--photo__slider .carousel-controls a:last-child svg {
	transform: rotate(180deg);
}

.card--photo__slider .carousel-controls a:hover {
	background-color: #c5a054;
}

.card--photo__slider .carousel-controls a .pageFill {
	fill: #c5a054;
	transition: fill .3s;
}

.card--photo__slider .carousel-controls a:hover .pageFill {
	fill: white;
}