.site-header__logo {
	flex-basis: 200px;
	flex-grow: 1;
	height: 100%;
	background-color: #45474e;
}

.site-header__logo a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 40px 0 30px;
	text-align: center;
}

.site-header__logo svg {
	width: 130px;
	height: 74px;
}

.site-header__logo-icon {
	fill: #e3b65c;
	transition: fill .5s;
}

.site-header__logo:hover .site-header__logo-icon {
	fill: white;
}

@media (width <= 1199px) {
	.site-header__logo {
		flex-basis: 50%;
		max-width: 50%;
	}
}

@media (width <= 767px) {
	.site-header__logo {
		flex-basis: 100%;
		max-width: 100%;
		margin-top: 60px;
	}
}

@media (width >= 1200px) {
	.is-fixed .site-header__logo {
		flex-basis: 90px;
		max-width: 90%;
		flex-grow: 0;
		background-color: transparent;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.is-fixed .site-header__logo a {
		padding: 0;
	}

	.is-fixed .site-header__logo svg {
		width: 90px;
		height: 51px;
	}

	.is-fixed .site-header__logo-icon {
		fill: #c5a054;
		@apply --transitionOpacityStart;
	}

	.is-fixed .site-header__logo:hover .site-header__logo-icon {
		@apply --transitionOpacityEnd;
		fill: #c5a054;
	}
}
