.panel {
	box-shadow: none;
	margin-bottom: 25px;
}

.panel-heading,
.panel-body  {
	padding: 0;
}

.panel-title a {
	display: flex;
	width: 100%;
	min-height: 61px;
	@apply --transitionOpacityStart;
}

.panel-title a:hover {
	@apply --transitionOpacityEnd;
}

.panel-title a span {
	flex-basis: 110px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #c5a054;
}

.panel-title a span b {
	color: white;
	@apply --r7;
	font-size: 27px;
}

.panel-title a span i {
	color: #45474e;
	@apply --o6;
	font-size: 12px;
	text-transform: uppercase;	
}

.panel-title a p {
	flex-basis: calc(100% - 110px);
	margin-bottom: 0;
	background-color: #f5f3f0;
	padding: 10px 25px;
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	display: flex;
	align-items: center;
}

.panel-body p {
	padding-top: 10px;
	color: #0b0c0f;
	@apply --r3;
	font-size: 16px;
	margin-bottom: 0;
}

.panel-body .read-more-link {
	color: #c5a054;
	background-color: #FFF;
	padding: 2px 5px;
	margin-left: 10px;
	text-transform: lowercase;
	transition: color .3s, background-color .3s;
	position: relative;
}

.panel-body .read-more-link::after {
	position: absolute;
	content: "";
	width: 0;
	height: 1px;
	background-color: #c5a054;

	bottom: 0;
	left: 0;
	transition: width 10s linear;
}

.panel-body .read-more-link:hover {
	color: #FFF;
	background-color: #c5a054;
}

.panel-body .read-more-link:hover::after {
	width: 100%;
	background-color: #FFF;
}