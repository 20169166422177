#navbarCat {
	display: none;
}

@media (width >= 1200px) {
	#navbarCat {
		display: block;
	}
	
	.category-menu {
		display: block;
		position: relative;
	}
}

#navbarCat {
	padding-left: 0;	
	padding-right: 0;
}

#navbarCat > ul {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	height: 100px;
}

#navbarCat > ul li {
	flex-basis: auto;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #c5a054;
	height: 100%;
	transition: background-color .3s;
}

#navbarCat > ul li:hover {
	background-color: #45474e;
}

#navbarCat > ul li a {
	width: 100%;
	color: white;
	transition: color .3s;
	@apply --r7;
	font-size: 16px;
	text-transform: uppercase;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 0;	
	padding-right: 0;	
}

#navbarCat > ul li svg {
	display: block;
	margin-right: 10px;
}

#navbarCat .catfill {
	transition: fill .3s;
	fill: white;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: #45474e;
	border-color: transparent;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	background-color: transparent;
}

#navbarCat .dropdown-menu {
	padding: 0;
	border: none;
	width: 100%;
}

#navbarCat .navbar-nav li:hover .dropdown-menu {
	display: block;
}

#navbarCat .dropdown-menu li {
	background-color: #45474e;
	transition: background-color .3s;
}

#navbarCat .dropdown-menu li:hover {
	background-color: #4C4E55;
}

#navbarCat .dropdown-menu li a {
	@apply --r4;
	font-size: 16px;
	padding: 25px 30px;
	border-bottom: 1px solid rgba(177,144,75,.3);
	white-space: normal;
	text-align: center;
}

#navbarCat .dropdown-menu li:last-child a {
	border-bottom: none;
}

#navbarCat .dropdown-menu li:hover a {
	color: #c5a054;
}

@media (width <= 1399px) {
	#navbarCat ul li a {
		font-size: 13px;
		font-weight: 400;
	}

	#navbarCat .dropdown-menu li a {
		font-size: 13px;
	}
}