.site-header__menu .site-header__tel {
	color: #010104;
	@apply --r3;
	font-family: Roboto;
	font-size: 17px;
	@apply --transitionOpacityStart;
	padding-left: 10px;
	padding-right: 10px;
	flex-basis: 175px;
	flex-shrink: 0;
}

.site-header__menu .site-header__tel:hover {
	@apply --transitionOpacityEnd;	
}

@media (width <= 1799px) and (width >= 1200px) {
	.site-header__menu .site-header__tel {
		margin-left: auto;
	}	
}

@media (width <= 1199px) {
	.site-header__menu .site-header__tel {
		margin-left: 0;
	}	
}

@media (width <= 767px) {
	.site-header__menu .site-header__tel {
		flex-basis: 50%;
		text-align: right;
		font-size: 20px;
	}	
}

@media (width <= 479px) {
	.site-header__menu .site-header__tel {
		flex-basis: 100%;
		text-align: center;
	}	
}

@media (width >= 1200px) {
	.is-fixed .site-header__tel {
		display: none;
	}
}