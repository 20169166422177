.index--sales {
	background-color: #F5F3F0;
	padding-top: 40px;
	padding-bottom: 40px;
}

.index--sales h1,
.index--sales h2 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	text-align: center;
	margin-bottom: 50px;
}

.index--sales__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media (width <= 767px) {
	.index--sales__wrapper {
		justify-content: center;
	}
}