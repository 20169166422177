.site-header__menu .site-header__call {
	flex-basis: 145px;
	flex-shrink: 0;
	text-align: center;
	color: #c5a054;
	@apply --r4;
	font-size: 17px;
	border-bottom: 1px dashed #c5a054;
	margin-left: 10px;
	margin-right: 10px;
}

.site-header__menu .site-header__call:hover {
	border-bottom-color: transparent;
}

@media (width <= 767px) {
	.site-header__menu .site-header__call {
		font-size: 20px;
		flex-basis: 170px;
		margin-right: auto;
	}
}

@media (width <= 479px) {
	.site-header__menu .site-header__call  {
		flex-basis: 100%;
		text-align: center;
		margin-left: 0;
		border-bottom: none;
		margin-top: 10px;
	}	
}

@media (width >= 1200px) {
	.is-fixed .site-header__call {
		display: none;
	}
}