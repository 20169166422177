.site-footer__subscription h3 {
	margin-top: 0;
	color: #0b0c0f;
	@apply --r3;
	font-size: 20px;
}

.site-footer__subscription-form {
	width: 100%;
	height: 40px;
	background-color: white;
	border: 1px solid #c5a054;
	display: flex;
}

.site-footer__subscription-form svg {
	display: none;
}

.site-footer__subscription-email {
	flex-basis: calc(100% - 125px);
	color: #7a7a7a;
	@apply --r3;
	font-size: 13px;
	font-weight: 300;
	border: none;
	padding-left: 10px;
	padding-right: 10px;
}

.site-footer__subscription-submit {
	flex-basis: 125px;
	border-width: 0 0 0 1px;
	border-color: #c5a054;
	background-color: #c5a054;
	color: white;
	@apply --r4;
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	transition: color .3s, background-color .3s;
}

.site-footer__subscription-submit:hover {
	background-color: white;
	color: #c5a054;
}

.site-footer__subscription-form ::-webkit-input-placeholder {width: 100%;padding-left: 0;}
.site-footer__subscription-form ::-moz-placeholder          {width: 100%;padding-left: 0;}
.site-footer__subscription-form :-ms-input-placeholder      {width: 100%;padding-left: 0;}

@media (width <= 991px) {

	.site-footer__subscription-form {
		position: relative;
	}

	.site-footer__subscription-form svg {
		position: absolute;
		display: block;
		width: 9.51px;
		height: 18px;
		top: 10px;
		right: 13px;
		pointer-events: none;
	}

	.site-footer__subscription-form .aboutGold {
		fill: white;
		transition: fill .3s;
	}

	.site-footer__subscription-submit:hover + svg .aboutGold {
		fill: #c49f53;
	}

	.site-footer__subscription-email {
		flex-basis: calc(100% - 40px);
	}

	.site-footer__subscription-submit {
		font-size: 0;
		flex-basis: 40px;
		position: relative;
	}
}

@media (width <= 767px) {
	.site-footer__subscription-form svg {
		display: none;
	}

	.site-footer__subscription-email {
		flex-basis: calc(100% - 125px);
	}

	.site-footer__subscription-submit {
		font-size: 14px;
		flex-basis: 125px;
	}
}