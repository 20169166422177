.site-header__search-form {
	position: relative;
	width: 24px;
	height: 64px;
	padding-top: 20px;
	padding-bottom: 20px;
	cursor: pointer;
	margin-right: 20px;
}

@media (width <= 1799px) {
	.site-header__search-form {
		position: absolute;
		right: 0;
		width: 50px;
		bottom: -7px;
		z-index: 1;
	}
}

.site-header__search-input {
	color: #010104;
	@apply --r3;
	font-size: 16px;
	font-style: italic;
	border: none;
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	width: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	transition: width .3s;
}

@media (width <= 1799px) {
	.site-header__search-input {
		padding-top: 10px;
		padding-bottom: 10px;
		top: 10px;
	}
}

.is-active .site-header__search-input {
	display: block;
	width: 350px;
}

@media (width <= 479px) {
	.is-active .site-header__search-input {
		width: 280px;		
	}
}

.site-header__search-input:focus {
	outline-color: transparent;
	caret-color: #e4b75c;
}

.site-header__search-button {
	background-color: transparent;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 24px;
	height: 24px;
	padding: 0;
	top: 20px;
	right: 0;
	pointer-events: none;
}

.is-active .site-header__search-button {
	pointer-events: all;
}

.site-header__search-button:focus {
	outline-color: transparent;	
}

#siteHeaderSearch {
	width: 18px;
	height: 23px;
	transition: transform .3s;
}

.site-header__search-form:hover #siteHeaderSearch {
	transform: scale(1.25);
}

.site-header__search-icon {
	fill: #e4b75c;
}

@media (width < 1200px) {
	.site-header__search-form {
		bottom: -52px;
	}
} 

@media (width <= 1199px) {
	.site-header__search-form {
		position: absolute;
		right: 0;
		bottom: -72px;
		width: 50px;
	}
	.site-header__search-icon {
		fill: #45474e;
	}	
}

@media (width >= 1200px) {
	.is-fixed .site-header__search-form {
		display: none;
	}
}