h1 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;	
}

@media (width <= 767px) {
	h1 {
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.text-page {
	background-color: #FFF;
	padding: 40px 50px;
	margin-top: 50px;
	margin-bottom: 90px;
	box-shadow: 0 4px 18px rgba(105, 100, 99, 0.14);
}

.text-page h2 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 30px;
}

.text-page h3 {
	color: #0b0c0f;
	@apply --r4;
	font-size: 30px;
}

.text-page h4 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 24px;
}

.text-page p,
.text-page li
{
	color: #0b0c0f;
	@apply --r4;
	font-size: 18px;
}

.text-page ul {
	list-style: none;
}

.text-page ol {
	list-style: none;
}

.text-page li {
	position: relative;
}

.text-page li::before {
	content: "";
	width: 6px;
	height: 6px;
	background-color: #c5a054;
	top: 15px;
	left: -20px;
	position: relative;
	display: block;
	border-radius: 50%;
}

.text-page img {
	max-width: 100%;
}