.fancybox-skin {
	border-radius: 0;
}

.fancybox-title {
	padding: 10px;
}

.fancybox-title p {
	color: #0b0c0f;
	@apply --r3;
	font-size: 16px;
	margin-top: 10px;
}