.contacts__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 50px;
	margin-bottom: 50px;
}

@media (width >= 1200px) {
	.contacts__wrapper {
		justify-content: center;
	}	
}