.inline-order-consult {
	padding: 70px 0;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

.inline-order-consult h2 {
	text-align: center;
	color: white;
	@apply --r5;
	font-size: 32px;
	max-width: 70%;
	min-width: 290px;
	margin: 0 auto 30px;
}

@media (width <= 767px) {
	.inline-order-consult h2 {
		font-size: 24px;
	}
}

.inline-order-consult a {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-basis: 260px;
	padding: 10px;
	color: #FFF;
	@apply --r3;
	font-size: 14px;
	text-transform: uppercase;
	background-color: #c5a054;
	border: 1px solid #c5a054;
	width: 260px;
	transition: color .3s, background-color .3s;
	margin-left: auto;
	margin-right: auto;
}

.inline-order-consult a:hover {
	color: #c5a054;
	background-color: #FFF;
}