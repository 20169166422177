.site-footer__social {
	margin-bottom: 35px;
}

@media (width <= 1799px) {
	.site-footer__social {
		margin-bottom: 20px;
	}	
}

.site-footer__social h3 {
	margin-top: 0;
	color: #0b0c0f;
	@apply --r3;
	font-size: 20px;
}

.site-footer__social-wrapper a {
	display: inline-block;
	margin-right: 15px;
}

@media (width <= 767px) {
	.site-footer__social-wrapper a {
		margin-left: 10px;
		margin-right: 10px;
	}
}

.socFill {
	fill: #dedfd7;
	transition: fill .3s;
}

.site-footer__social-wrapper a:hover .socFill {
	fill: #c5a054;
}