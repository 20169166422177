.sidebar__news {
	display: none;
}

@media (width >= 768px) {
	.sidebar__news {
		display: block;
	}
}

.sidebar__news-item {
	background-color: #FFF;
	padding: 25px 15px;
}

.sidebar__news-item span {
	display: block;
	color: #525663;
	@apply --r3;
	font-size: 14px;
	margin-bottom: 15px;
}

.sidebar__news-item h4 {
	margin-bottom: 25px;
}	

.sidebar__news-item h4 a {
	color: #0b0c0f;
	@apply --r4;
	font-family: Roboto;
	font-size: 16px;
}

.sidebar__news-item p {
	color: #0b0c0f;
	@apply --r3;
	font-size: 16px;
	margin-bottom: 20px;
}

.sidebar__news-item > a {
	color: #c5a054;
	@apply --r4;
	font-size: 14px;
}

@media (width >= 1200px) {
	.sidebar__news-item a {
		@apply --transitionOpacityStart;
	}	
	.sidebar__news-item a:hover {
		@apply --transitionOpacityEnd;	
	}
}
