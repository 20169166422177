.showroom__details {
	flex-basis: 100%;
}

.showroom__details-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 50px;
}

@media (width <= 991px) {
	.showroom__details-wrapper {
		justify-content: flex-start;
	}
}

.showroom__details-item {
	margin-bottom: 40px;
	flex-basis: 30%;
}

@media (width <= 991px) {
	.showroom__details-item {
		flex-basis: 100%;
	}
}

.showroom__details h1 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	margin-top: 20px;
}

.showroom__details h2 {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	padding-bottom: 10px;
	margin-bottom: 10px;
	position: relative;
}

.showroom__details h2::after {
	position: absolute;
	content:"";
	bottom: 0;
	left: 0;
	width: 60px;
	height: 2px;
	background-color: #ddae52;
	transition: width 10s;
}

.showroom__details-item:hover h2::after {
	width: 100%;
}

.showroom__details p {
	color: #0b0c0f;
	@apply --r5;
	font-size: 18px;
}

.showroom__details a {
	color: #0b0c0f;
	@apply --r5;
	@apply --transitionOpacityStart;
	font-size: 18px;
	display: block;
	margin-bottom: 10px;
}

.showroom__details a:hover {
	@apply --transitionOpacityEnd;
}