.breadcrumb {
	width: 100%;
	flex-basis: 100%;
	padding-top: 5px;
	padding-bottom: 25px;
	text-align: left;
	background-color: transparent;
	margin-bottom: 0;
}

@media (width >= 768px) {
	.breadcrumb {
		padding-left: 0;
		padding-right: 0;		
	}
}

.breadcrumb li a {
	display: inline-block;
	@apply --r5;
	font-size: 14px;
	text-transform: uppercase;
	padding: 10px 0;
	line-height: 1;
	color: #c5a054;
}

.breadcrumb .active a {
	color: #0b0c0f;
	cursor: default;
}

.breadcrumb a:focus {
	text-decoration: none;
}

.breadcrumb > li + li {
	margin-left: 10px;
	position: relative;
}

.breadcrumb > li + li::before {
	content: "/";
	@apply --r5;
	line-height: 1;
	color: #0b0c0f;
	font-size: 13px;
	display: block;
	position: absolute;
	left: -14px;
	top: 9px;
}

@media (width <= 767px) {
	.breadcrumb {
		display: none;
	}
}