.fluid {
	width: 100%;
	padding: 0 85px; 
	background-color: #F5F3F0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 40px;
}

@media (width <= 1199px) {
	.fluid {
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media (width <= 767px) {
	.fluid {
		display: block;
		background-color: #FFF;
	}
}