.contacts__details {
	flex-basis: 50%;
}

@media (width <= 991px) {
	.contacts__details {
		flex-basis: 100%;
	}	
}

.contacts__detail-item {
	margin-bottom: 40px;
}

.contacts__details h1 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	margin-top: 0;
}

.contacts__details h2 {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	padding-bottom: 10px;
	margin-bottom: 10px;
	position: relative;
}

.contacts__details h2::after {
	position: absolute;
	content:"";
	bottom: 0;
	left: 0;
	width: 60px;
	height: 2px;
	background-color: #ddae52;
	transition: width 10s;
}

.contacts__detail-item:hover h2::after {
	width: 100%;
}

.contacts__details p {
	color: #0b0c0f;
	@apply --r5;
	font-size: 18px;
}

.contacts__details a {
	color: #0b0c0f;
	@apply --r5;
	@apply --transitionOpacityStart;
	font-size: 18px;
	display: block;
	margin-bottom: 10px;
}

.contacts__details a:hover {
	@apply --transitionOpacityEnd;
}