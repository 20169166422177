.index--clients__wrapper {
	display: flex;
	justify-content: space-between;
	/* flex-wrap: wrap; */
	overflow: hidden;
}

.index--clients__heading {
	background-color: #c5a054;
	padding: 30px;
	flex-basis: 290px;
	height: 180px;
}

@media (width <= 991px) {
	.index--clients__wrapper {
		flex-wrap: wrap;
	}
	
	.index--clients__heading {
		flex-basis: 100%;
		height: auto;
	}
}

.index--clients__heading h2 {
	color: white;
	@apply --o4;
	font-size: 20px;
	text-transform: uppercase;
	text-align: right;
	margin-top: 0;
	margin-bottom: 0;
}

.index--clients__heading span {
	display: block;
	margin-bottom: 10px;
}

@media (width <= 991px) {
	.index--clients__heading h2 {
		text-align: center;
		width: 320px;
		margin-left: auto;
		margin-right: auto;
	}

	.index--clients__heading span {
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		margin-bottom: 0;
	}
}

@media (width <= 479px) {
	.index--clients__heading h2 {
		width: auto;
	}
}

.index--clients__heading span:last-child {
	margin-bottom: 0;
}

.index--clients__slider.owl-carousel {
	flex-basis: calc(100% - 290px);
	width: calc(100% - 290px);
	background-color: #45474e;
	height: 180px;
}

.index--clients__slider-item {
	height: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.index--clients__slider-item img {
	max-width: 100%;
	height: auto;
}

@media (width <= 991px) {
	.index--clients__slider.owl-carousel {
		flex-basis: 100%;
		width: 100%;
	}
}