.index--news {
	padding-top: 40px;
	padding-bottom: 40px;
}

.index--news__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media (width <= 991px) {
	.index--news__wrapper {
		justify-content: center;
	}
}

.index--news__accordion {
	flex-basis: calc(50% - 20px);
}

@media (width <= 991px) {
	.index--news__accordion {
		flex-basis: 100%;
	}
}

.index--news__accordion h1,
.index--news__accordion h2 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	text-align: left;
	padding-bottom: 40px;
	margin-bottom: 40px;
	position: relative;
}

.index--news__accordion h1::after,
.index--news__accordion h2::after {
	position: absolute;
	content:"";
	bottom: 0;
	left: 0;
	width: 60px;
	height: 2px;
	background-color: #ddae52;
	transition: width 10s;
}

.index--news:hover h1::after,
.index--news:hover h2::after {
	width: 100%;
}




.index--news__banner {
	flex-basis: calc(50% - 20px);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 145px;

}

.index--news__banner:hover {
	@apply --transitionOpacityEnd;	
}

.index--news__banner a {
	width: 100%;
	height: 100%;
}

.index--news__banner img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

@media (width <= 991px) {
	.index--news__banner {
		flex-basis: 100%;
		margin-top: 40px;
	}
}