.remodal-is-locked body {
	overflow-y: scroll;
	top: 0;
	right: 0;
	left: 0;
	height: 100%;
	position: fixed;
}

.remodal {
	max-width: 710px;
	background-color: white;
	border-radius: 5px;
	padding: 40px 80px;
}

@media (width <= 767px) {
	.remodal {
		padding-top: 20px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.remodal h2 {
	color: #45474e;
	@apply --r5;
	font-size: 30px;
	margin-bottom: 30px;
}

.remodal p {
	color: #45474e;
	@apply --r4;
	font-family: Roboto;
	font-size: 16px;
	text-align: left;
	margin-bottom: 20px;
}

.remodal .form-group {
	margin-bottom: 25px;
}

.remodal .form-group input,
.remodal .form-group textarea {
	color: #797a7f;
	@apply --r4;
	font-size: 18px;
	border: 1px solid #797a7f;
	border-radius: 5px;
}

.remodal .form-group input {
	height: 45px;
}

.remodal .form-group textarea {
	min-height: 95px;
	resize: vertical;	
}

.remodal [type='submit'] {
	color: white;
	@apply --r4;
	font-size: 14px;
	width: 150px;
	height: 52px;
	background-color: #c5a054;
	border: 1px solid #c5a054;
	transition: color .3s, background-color .3s;
	margin-top: 15px;
}

.remodal [type='submit']:hover {
	color: #c5a054;
	background-color: white;
}

.remodal-close:before {
	font-size: 35px;
	top: 5px;
	left: -5px;
}

.form-control:focus {
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #c5a054;
}