.category-menu {
	display: none;
}

@media (width >= 1200px) {
	.category-menu {
		display: block;
		position: relative;
	}
}

.category-menu__wrapper ul {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	height: 100px;
}

.category-menu__wrapper ul li {
	flex-basis: calc(100% / 6);
	padding-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #c5a054;
	height: 100%;
	transition: background-color .3s;
}

.category-menu__wrapper ul li:hover {
	background-color: #45474e;
}

.category-menu__wrapper ul li a {
	color: white;
	@apply --r7;
	font-size: 16px;
	text-transform: uppercase;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (width <= 1399px) {
	.category-menu__wrapper ul li a {
		font-size: 14px;
		font-weight: 500;
	}
}

.category-menu .category-menu__submenu {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -580px;
	left: 0;
	width: 100%;
	height: 580px;
	visibility: hidden;
	opacity: 0;
	transition: opacity .5s, visibility .5s;
}

.category-menu li:hover .category-menu__submenu {
	visibility: visible;
	opacity: 1;
}


.category-menu .category-menu__submenu li {
	flex-grow: 1;
	border-right: 2px solid white;
	flex-basis: 33%;
	padding-right: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.category-menu .category-menu__submenu li::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #45474e;
	content: "";
	pointer-events: none;
	opacity: 0;
	transition: opacity .3s;
}

.category-menu .category-menu__submenu li:hover::after {
	opacity: 0.5;
}

.category-menu__submenu li:last-child,
.category-menu__submenu li:only-child {
	border-right: none;
}

.category-menu .category-menu__submenu li a {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	color: white;
	@apply --r3;
	font-size: 35px;
	text-transform: uppercase;
}