.contacts__map {
	width: 100%;
	height: 400px;
	z-index: 0;
	position: relative;
}

@media (width >= 1200px) {
	.contacts__map::before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.3);
		transition: background-color .3s;
		pointer-events: none;
	}

	.is-active.contacts__map::before {
		background-color: rgba(0,0,0,0);
	}	
}
