@media (width >= 768px) {
	.sidebar__category .sidebar__heading {
		display: none;
	}
}

.sidebar__category {
	position: relative;
	background-color: #f5f3f0;
	z-index: 1;
	overflow: auto;
}

.sidebar__category .navbar-nav {
	list-style: none;
	float: none;
	padding-left: 0;
	width: 100%;
	display: block;
}

.sidebar__category .navbar-nav > li {
	width: 100%;
	border-bottom: 1px solid #c9c9c9;
}

.sidebar__category .navbar-nav > li.dropdown.open,
.sidebar__category .navbar-nav > li:last-child {
	border-bottom: none;
}

.sidebar__category .navbar-nav > li > a {
	padding: 15px;
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	text-transform: none;
	background-color: transparent;
	transition: color .3s;
}

@media (width >= 768px) {
	.sidebar__category .navbar-nav > li.active > a,
	.sidebar__category .navbar-nav > li.dropdown.open > a,
	.sidebar__category .navbar-nav > li:hover > a {
		color: white;
		background-color: #525663;
	}
}


.sidebar__category .navbar-nav .dropdown-menu {
	position: static;
	width: 100%;
	z-index: 1;
	border-color: #c9c9c9;
	border-width: 0 0 0 1px;
	border-radius: 0;
	box-shadow: none;
	background-color: transparent;
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 10px;
	margin-bottom: 10px;
}

.sidebar__category .dropdown-menu li {
	width: 100%;
}

.sidebar__category .dropdown-menu a {
	color: #0b0c0f;
	width: 100%;
	@apply --r4;
	font-size: 14px;
	padding: 10px 15px;
	text-transform: none;
	white-space: normal;
	background-color: transparent;
	transition: background .3s;
}

.sidebar__category .dropdown-menu li.active a,
.sidebar__category .dropdown-menu li:hover a {
	background-color: #FFF;
	color: #0b0c0f;
}

@media (width <= 767px) {
	.sidebar__category {
		width: 100%;
		position: fixed;
		height: 0;
	}

	.sidebar__category.collapse.in {
		height: 100%;
	}

	.sidebar__category .dropdown {
		position: relative;
	}

	.sidebar__category .dropdown::before,
	.sidebar__category .dropdown::after {
		position: absolute;
		content: "";
		width: 20px;
		height: 2px;
		top: 24px;
		background-color: black;
		right: 21px;
	}

	.sidebar__category .dropdown::after {
		transform: rotate(90deg);
		transition: transform .3s;
	}

	.sidebar__category .dropdown.open::after {
		transform: rotate(180deg);
	}

	.sidebar__category .dropdown-menu a {
		font-size: 18px;
	}
	.nav .open > a, .nav .open > a:hover,
	.nav .open > a:focus {
		background-color: transparent;
	}
}