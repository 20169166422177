.blog__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 40px;
}

@media (width <= 767px) {
	.blog__wrapper {
		justify-content: center;
	}
}