/* variables */
:root {
	--pink: #f2e2e1;
	--cyan: #e0e8ef;
}

/* properties */
:root {
	
	--transitionOpacityStart {
		will-change: opacity;
		opacity: 1;
		transition: opacity .3s;
	};
	--transitionOpacityEnd {
		opacity: .7;
		transition: opacity .3s;
	};
	--transitionBackgroundColor {	transition: background-color .3s;	};
	--transitionColor {	transition: color .3s;	};
	--transitionWidth { transition: width .3s; };
	--transitionAll {	transition: all .3s; }
	--fluidBorder {
		position: absolute;
		content: "";
		right: 0;
		height: 1px;
		left: 0;
		bottom: 0;
		background: currentColor;
		opacity: .25;
		transform: scaleX(.85) translateY(.085rem);
		transition: .2s;
	};
	--fluidBorderHover {
		opacity: .75;
		transform: scaleX(.95);
	}
	--boxShadow {
		box-shadow: 0 6px 13px rgba(34, 2, 2, 0.1);
		transition: box-shadow .3s;
	}
	--boxShadowHover {
		box-shadow: 0 6px 13px rgba(34, 2, 2, 0.2);
	}

	/* fonts */

	--o4 {
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		font-style: normal;
	}

	--o6 {
		font-family: 'Open Sans', sans-serif;
		font-weight: 600;
		font-style: normal;
	}

	--o7 {
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		font-style: normal;
	}

	--r3 {
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
		font-style: normal;
	}

	--r4 {
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-style: normal;
	}

	--r5 {
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-style: normal;
	}

	--r7 {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-style: normal;
	}
}