@media (width >= 768px) {
	.main-content__sidebar-buttons {
		display: none;
	}
}
.main-content__sidebar-buttons {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	border-top: 1px solid #e5e5e6;
	border-bottom: 1px solid #e5e5e6;
}

.main-content__sidebar-buttons button {
	flex-basis: 50%;
	max-width: 50%;
	min-width: 240px;
	color: #525663;
	@apply --r3;
	font-size: 20px;
	transition: color .3s;
}

@media (width <= 480px) {
	.main-content__sidebar-buttons button {
		flex-basis: 100%;
		max-width: 100%;
	}
}

.main-content__sidebar-buttons button:hover {
	color: #c5a054;
}