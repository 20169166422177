.youtube {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: inline-block;
	overflow: hidden;
	transition: all 200ms ease-out;
	cursor: pointer;
	margin: 20px 0 35px;
}

.youtube .play {
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAgCAMAAABabbp1AAAA5FBMVEUAAADAGB3lLCfPHiDjKybEGR3ZJSPUIyLlLSfUIyLlLSfRISHlLSfiKiXiKybiKybaJyTCGB3kLCbiKybFGR7WJCPiKybKHR/bJyTlLSfJHR/lLSfFGh7kLCfGGx7DGR7TIiLBGB3PICHDGR7NHyHXJSTAGB3aJyTGGh7bJiTiKiXPHyDKHB/WJCPdKSXCGB7TIiL////LFBXvqqrZIB3UGBfVLS377/DXGhfnl5fkiYndfHzQFhb3/Pzv9vbz4uLo1tbqoqPdWFfZPjzSOTvVNDPTHBz55eXlzs7ytLPndHLNKizszpooAAAAKHRSTlMA7evt0OPt7eXk4eHZoH5bF8/GlT8q98nBvbSxpaSPhWtdIgzVz8OuJBK0tQAAAStJREFUOMuVy9d6gkAQQOFRwRCk2Hs3VTAJK6KCPWra+79PFkgu3GX9ds/czfwDYXnTKOmtRlGSNE1VFSUdpSiqqmmSVGy0OiXDrMBfRnrMUbWTj7Q+5kwKvTnmTse8yM+rQ6i8CNQHU4TrYDwLVIcStXNdJpfgkdLfhwXrIQt1crX4cvbvC5fB7ydEs6njzJf4YUKXhVwCx23908xN4LfJ3FnvAo96uGFx3OYz8M4UfyNaYR633uwO54sbi8dtA88ieM4iQv/8w/dWxC3D5HPfQ7bFyefLU4hpfkdzjI8xpnnNsuyLQfvpEaHwaJODedMm+0HIZpSBti2QDF0RXoP+k0BNKIvwHoxkAV4G6PLrAgDk5VfeyoCrFPhwagBRo0G7IKeuJz/0hpj+Akgf51FRltPPAAAAAElFTkSuQmCC')no-repeat center center;
	background-size: 46px 32px;
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 1;
	filter: opacity(1);
	transition: all 0.3s ease-out;
}

.youtube .play:hover {
	opacity: .8;
	filter: opacity(.8);
}