.main-content__product-wrapper {
	margin-top: 40px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

@media (width <= 479px) {
	.main-content__product-wrapper {
		justify-content: center;
	}
}