.main-content__heading h1 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	text-align: center;
}

@media (width <= 767px) {
	.main-content__heading h1 {
		@apply --r4;
		font-size: 28px;
		text-align: center;
	}
}