.card--product {
	min-width: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-basis: calc(25% - 30px);
	background-color: #FFF;
	@apply --boxShadow;
	margin-bottom: 30px;
}

.main-content__product-wrapper .card--product {
	margin-left: 5px;
	margin-right: 5px;
}

@media (width <= 1199px) {
	.main-content__product-wrapper .card--product {
		flex-basis: calc(33% - 15px);
	}
}

@media (width <= 991px) {
	.main-content__product-wrapper .card--product,
	.card--product {
		flex-basis: calc(50% - 15px);
	}
}

@media (width <= 479px) {
	.main-content__product-wrapper .card--product,
	.card--product {
		flex-basis: 290px;
	}
}

.card--product:hover {
	@apply --boxShadowHover;	
}

.card--product a:nth-child(1) {
	display: block;
	width: 100%;
	overflow: hidden;
}

.card--product a:nth-child(1) img {
	width: 101%;
	height: 101%;
	object-fit: cover;
}

.card--product a:nth-child(2) {
	padding: 20px;
	max-width: 100%;
}

.card--product a:nth-child(2) h3 {
	color: #0b0c0f;
	@apply --r4;
	font-size: 18px;
	margin-top: 0;
	text-align: center;
}

.card--product a:nth-child(2) p {
	color: #c5a054;
	@apply --r5;
	font-size: 16px;
	text-align: center;
	margin-bottom: 0;
}

.labelled {
	position: relative;
}

.labelled::before {
	position: absolute;
	width: 64px;
	height: 64px;
	top: 0;
	right: 0;
	color: white;
	@apply --r4;
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	line-height: 64px;
	pointer-events: none;
}

.labelled--new::before {
	background-color: #c5a054;	
	content: "NEW";
}

.labelled--sale::before {
	background-color: #6cd354;	
	content: "SALE";
}