.blog__preview {
	flex-basis: calc(33% - 20px);
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
}

@media (width <= 991px) {
	.blog__preview {
		flex-basis: calc(50% - 20px);
	}
}

@media (width <= 767px) {
	.blog__preview {
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media (width <= 641px) {
	.blog__preview {
		flex-basis: 100%;
		max-width: 380px;
		margin-left: auto;
		margin-right: auto;
	}
}

.blog__preview .img-wrapper {
	display: block;
	position: relative;
}

.blog__preview img {
	width: 100%;
	height: auto;
}

.blog__preview .img-wrapper::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	will-change: opacity;
	transition: opacity .3s;
	background-image: linear-gradient(180deg, #76777c 0%, #857e73 100%);
}

.blog__preview .img-wrapper:hover::after {
	opacity: .9;
}

.blog__preview .img-wrapper span {
	opacity: 0;
	will-change: opacity;
	transition: opacity;
	color: white;
	@apply --r3;
	font-size: 16px;
	display: flex;
	align-items: center;
	position: absolute;
	z-index: 1;
}

.blog__preview .img-wrapper span:first-of-type {
	bottom: 20px;
	left: 50px;
}

.blog__preview .img-wrapper span:last-of-type {
	bottom: 20px;
	right: 50px;
}

.blog__preview .img-wrapper:hover span {
	opacity: 1;
}

.blog__preview .img-wrapper svg {
	display: block;
	margin-right: 10px;
}

.blog__preview .img-wrapper:hover svg {
	animation: eyeblink 5s infinite;
}

@keyframes eyeblink {
	28% {transform: scaleY(1);}
	30% {transform: scaleY(.2);}
	32% {transform: scaleY(1);}
	80% {transform: scaleY(1);}
	82% {transform: scaleY(.2);}
	84% {transform: scaleY(1);}
	86% {transform: scaleY(.2);}
	88% {transform: scaleY(1);}
}

.blog__preview .eyeFill {
	fill: white;
}

.blog__preview h2 {
	color: #0b0c0f;
	@apply --r7;
	font-size: 18px;
	text-align: center;
	position: relative;
	margin-top: 20px;
	margin-bottom: 10px;
	padding-bottom: 10px;
	@apply --transitionOpacityStart;
}

.blog__preview h2:hover {
	@apply --transitionOpacityEnd;
}

.blog__preview h2::after {
	position: absolute;
	content: "";
	bottom: 0;
	left: calc(50% - 30px);
	width: 60px;
	height: 2px;
	background-color: #c5a054;
	transition: background-color .3s;
}


.blog__preview:hover h2::after {
	animation: parabola 10s linear;
}

@keyframes parabola {
	0% { transform: scaleX(1) }
	100% { transform: scaleX(0) }
}

.blog__preview p {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	text-align: center;
}

.blog__preview .blog-read-more {
	display: inline-block;
	margin: 20px auto;
	text-align: center;
	text-decoration: none;
	color: #c5a054;
	@apply --r7;
	font-size: 18px;
	border-bottom: 1px solid currentColor;
	@apply --transitionOpacityStart;
}

.blog__preview .blog-read-more:hover {
	border-bottom-style: dashed;
	@apply --transitionOpacityEnd;
}