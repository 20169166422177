.navbar-collapse {
	flex-grow: 1;
}

.navbar-nav {
	width: 100%;
	display: flex;
}

.close-button {
	position: absolute;
	top: 40px;
	right: 40px;
	display: block;
	width: 24px;
	height: 24px;
	cursor: pointer;
	opacity: 1;
	will-change: opacity;
	transition: opacity .3s;
}

.close-button:hover {
	opacity: .7;
}

.close-button::before,
.close-button::after {
	width: 20px;
	height: 2px;
	background-color: #1f2126;
	content:"";
	position: absolute;
	top: 10px;
	left: 3px;
}

.close-button::before {
	transform: rotate(45deg);
}

.close-button::after {
	transform: rotate(-45deg);
}

@media (width >= 1200px) {
	.close-button {
		display: none;
	}
}

@media (width <= 1799px) {
	.navbar-collapse {
		order: 1;
		flex-basis: 100%;
		box-shadow: 0 4px 18px rgba(105, 100, 99, 0.14);
	}

	.navbar-nav {
		width: calc(100% - 50px);
	}
}

@media (width >= 1200px) {
	.navbar-nav--fixed {
		display: none;
	}
}

.menu-item.menu-item--fixed {
	text-align: center;
}

.menu-item.menu-item--fixed svg {
	display: block;
	margin-left: 10px;
	margin-right: 10px;
}

.menu-item.menu-item--fixed .catfill {
	fill: white;
	transition: fill .3s;
}

@media (width <= 1199px) {
	.menu-item.menu-item--fixed:hover .catfill {
		fill: #45474e;
	}
}


.menu-item.menu-item--regular {
	flex-grow: 1;
	text-align: center;
}

.navbar-nav > li > a {
	padding-left: 20px;
	padding-right: 20px;
	@apply --r4;
	font-size: 16px;
	text-transform: uppercase;
	color: #0b0c0f;
	transition: color .3s;
}

.navbar-nav > li > a:hover,
.navbar-nav > .active > a {
	color: #c5a054;
}

.navbar-nav > .active > a {
	font-weight: 500;
}

.nav > li > a:hover,
.nav > li > a:focus {
	background-color: transparent;
}

@media (width <= 1199px) {
	.navbar-collapse {
		position: fixed;
		z-index: 5;
		background-color: #CFA859;
		top: 0;
		width: 100%;
		height: 100%;
		padding: 0;
		left: 0;
		right: 0;
		box-shadow: none;
	}

	.navbar-nav {
		display: block;
		width: 100%;
		margin: 0;
	}

	.navbar-nav--fixed {
		padding-top: 90px;
		padding-bottom: 35px;
		border-bottom: 1px solid #b5924b;
	}

	.navbar-nav--regular {
		padding-top: 35px;
		padding-bottom: 35px;
		border-top: 1px solid rgba(255,255,255,.3);
	}

	.navbar-nav li {
		width: 280px;
		margin-left: auto;
		margin-right: auto;
	}

	.navbar-nav li svg {
		width: 42px;
	}

	.navbar-nav--fixed li a {
		display: flex;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
	}

	.navbar-nav li a {
		@apply --r7;
		font-size: 16px;
		text-transform: uppercase;
		color: white;
	}

	.navbar-nav li:hover a,
	.navbar-nav .active a {
		color: #45474e;
	}

	.menu-item.menu-item--fixed {
		text-align: left;
	}
}

@media (width >= 1200px) {
	.is-fixed .site-header__menu {
		flex-basis: calc(100% - 90px);
		margin-right: 0;
		box-shadow: none;
		margin-top: 0;
	}

	.is-fixed .navbar-collapse {
		flex-basis: auto;
		order: 0;
		box-shadow: none;
	}

	.is-fixed .navbar-nav--regular {
		display: none;
	}

	.is-fixed .navbar-nav {
		width: 100%;
	}

	.is-fixed .navbar-nav--fixed {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.is-fixed .navbar-nav--fixed li {
		text-align: center;
		flex-grow: 1;
	}

	.is-fixed .navbar-nav--fixed li a {
		padding: 0;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.is-fixed .navbar-nav--fixed li a::before {
		display: none;
	}

	.is-fixed .menu-item.menu-item--fixed svg {
		display: none;
	}
}