.sidebar__social {
	display: none;
}

@media (width >= 768px) {
	.sidebar__social {
		display: block;
		padding: 25px 0 65px;
	}
}

.sidebar__social-heading {
	color: #0b0c0f;
	@apply --r4;
	line-height: 1.5;
	font-size: 16px;
	text-transform: uppercase;
	text-align: center;
}

.sidebar__social-item {
	padding: 5px;
}

.sidebar__social-item iframe {
	display: block;
	margin-left: auto!important;
	margin-right: auto!important;
}