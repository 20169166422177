.card--photo__advantages {
	flex-basis: 33%;
	max-width: 33%;
	padding-left: 50px;
}

@media (width <= 1199px) {
	.card--photo__advantages {
		flex-basis: 100%;
		max-width: 100%;
		padding-left: 0;
		margin-top: 50px;
	}	
}

.card--photo__advantages-item {
	margin-bottom: 30px;
	box-shadow: 0 4px 18px rgba(105, 100, 99, 0.14);
}

.card--photo__advantages-item h3 {
	padding: 15px 30px;
	color: #45474e;
	@apply --r5;
	font-size: 18px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	background-color: #FFF;
	margin-top: 0;
}

.card--photo__advantages-item h3 svg {
	margin-right: 10px;
}

.card--photo__advantages-item p {
	color: #0b0c0f;
	@apply --r3;
	font-size: 16px;
	padding: 15px 30px;
	background-color: #F5F3F0;
}

.card--photo__advantages .advGold {
	fill: #e4b75c;
	transition: fill .3s;
}

.card--photo__advantages .advDark {
	fill: #565656;
	transition: fill .3s;
}

.card--photo__advantages-item:hover .advDark {
	animation: adv-blink .4s linear;
}

.card--photo__advantages-item:hover .advGold {
	animation: adv-blink .6s linear;
}

@keyframes adv-blink {
	0% {opacity: 0}
	70% {opacity: .2}
	100% {opacity: 1}
}

.card--photo__advantages-item h4 {
	font-size: 18px;
	@apply --r5;
	color: #c5a054;
	padding-left: 30px;
	padding-right: 30px;
}

.card--photo__advantages-item a {
	margin-left: 30px;
	margin-right: 30px;
	display: inline-block;
}

.card--photo__advantages-item .advantages-item__tel {
	color: #0b0c0f;
	display: block;
	margin-bottom: 10px;
	font-size: 18px;
	@apply --r4;
	@apply --transitionOpacityStart;
}

.card--photo__advantages-item .advantages-item__tel:hover {
	@apply --transitionOpacityEnd;
}

.card--photo__advantages-item .advantages-item__call {
	font-size: 18px;
	@apply --r4;
	color: #c5a054;
	border-bottom: 1px dashed currentColor;
	margin-bottom: 30px;
	display: inline-block;
	transition: border-color .3s;
}

.card--photo__advantages-item .advantages-item__call:hover {
	border-bottom-color: transparent;
}