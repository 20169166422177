.similar-products {
	
}

.similar-products h2 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;
}

.similar-products__wrapper {
	margin-top: 40px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

@media (width <= 479px) {
	.similar-products__wrapper {
		justify-content: center;
	}
}