table {
	border: none!important;
	width: 100%!important;
	max-width: 100%!important;
	overflow-x: auto!important;
	min-height: 0.01%!important;
}

table > tbody > tr > td {
	padding: 20px 30px;
	line-height: 1.42857143;
	vertical-align: top;
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	text-transform: none;
}

table > tbody > tr > td:first-child {
	@apply --r7;
}

table > tbody > tr:nth-of-type(odd) {
	background-color: #f6f6f6;
}