@media (width >= 480px) {}
@media (width >= 768px) {}
@media (width >= 992px) {}
@media (width >= 1200px) {}
@media (width >= 1400px) {}
@media (width >= 1680px) {}
@media (width <= 1799px) and (width > 1199px) {}
@media (width <= 1799px) {}
@media (width <= 1679px) {}
@media (width <= 1399px) {}
@media (width <= 1199px) {}
@media (width <= 991px) {}
@media (width <= 767px) {}
@media (width <= 641px) {}
@media (width <= 479px) {}

body {
	min-width: 320px;
}



a {
	outline-color: transparent!important;
}

a:hover,
a:focus {
	text-decoration: none;
}

@media (width <= 767px) {
	.standard-wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}
}





.site-main--card,
.site-main--contacts,
.site-main--text {
	background-color: #f5f3f0;
}




.mail {
	display: none;
}