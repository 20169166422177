.card--photo__text {
	flex-basis: 33%;
	max-width: 33%;
	padding-left: 50px;
}

@media (width <= 1199px) {
	.card--photo__text {
		flex-basis: 50%;
		max-width: 50%;
	}	
}

@media (width <= 767px) {
	.card--photo__text {
		flex-basis: 100%;
		max-width: 100%;
		padding-left: 30px;
		padding-right: 30px;
		margin-left: auto;
		margin-right: auto;
	}		
}

@media (width <= 479px) {
	.card--photo__text {
		padding-left: 15px;
		padding-right: 15px;
	}		
}

.card--photo__text h1 {
	color: #0b0c0f;
	@apply --r5;
	font-family: Roboto;
	font-size: 30px;
	margin-top: 0;
}

.card--photo__text p {
	color: #0b0c0f;
	@apply --r4;
	font-size: 18px;
}

.card--photo__text ul {
	list-style: none;
}

.card--photo__text li {
	color: #0b0c0f;
	@apply --r4;
	font-size: 18px;
	position: relative;	
}

.card--photo__text li::before {
	content: "";
	width: 6px;
	height: 6px;
	background-color: #c5a054;
	top: 15px;
	left: -20px;
	position: relative;
	display: block;
	border-radius: 50%;
}

.card--photo__text li a {
	color: #c5a054;
	border-bottom: 1px dashed currentColor;
}

.card--photo__text li a:hover {
	border-bottom: none;
}

.card--photo__text h4 {
	color: #0b0c0f;
	@apply --r4;
	font-size: 18px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.card--photo__text h4 b {
	font-size: 24px;
	padding-left: 10px;
}

.one-click-order {
	width: 246px;
	height: 50px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	@apply --r5;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	background-color: #c5a054;
	border: 1px solid #c5a054;
	transition: background-color .3s, color .3s;
}

.one-click-order:focus {
	color: white;
}

.one-click-order:hover {
	color: #c5a054;
	background-color: transparent;
}

.social-share {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
}

.social-share p {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	text-transform: uppercase;
	margin-right: 10px;
}