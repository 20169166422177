.site-header__language {
	position: absolute;
	top: 0;
	right: 10px;
}

@media (width >= 1400px) {
	.site-header__language {
		right: 100px;
	}
}

@media (width <= 1199px) {
	.site-header__language {
		right: 15px;
	}
}

.site-header__language a:not(:last-child) {
	margin-right: 20px;
}

.site-header__language a {
	display: inline-block;
	color: #4c4a47;
	@apply --r4;
	font-size: 13px;
	line-height: 1;
	padding-top: 15px;
	padding-bottom: 15px;
	text-transform: uppercase;
	transition: color .3s;
}

.site-header__language a:hover,
.site-header__language .link-lang--active {
	color: #c5a054;
}

.site-header__language .link-lang--active {
	font-weight: 500;
}

@media (width <= 767px) {
	.site-header__language {
		right: auto;
		left: calc(50% - 35px);
	}
	.site-header__language a {
		font-size: 20px;
	}
}

@media (width >= 1200px) {
	.is-fixed .site-header__language {
		display: none;
	}
}