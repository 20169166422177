.site-footer__logo {
	display: flex;
	flex-basis: 290px;
	max-width: 290px;
	padding: 25px 10px;
	background-color: #e5e5e6;
	@apply --transitionOpacityStart;
}

.site-footer__logo:hover {
	@apply --transitionOpacityEnd;
}

.site-footer__logo a {
	width: 100%;
	height: auto;
	display: block;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.site-footer__logo svg {
	width: 142px;
	height: 82px;
}

.site-footer__logo .site-header__logo-icon {
	fill: #0b0c0f;
}

.site-footer__logo p {
	margin-bottom: 0;
	color: #0b0c0f;
	@apply --r4;
	font-size: 14px;
	text-align: center;
	margin-top: 25px;
	max-width: 100%;
}

@media (width <= 1399px) {
	.site-footer__logo {
		flex-basis: 200px;
		max-width: 200px;
	}	
}

@media (width >= 1680px) {
	.site-footer__logo {
		flex-basis: 350px;
		max-width: 350px;
	}	
}

@media (width <= 1199px) {
	.site-footer__logo {
		display: none;
	}
}