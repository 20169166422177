.site-header__menu .site-header__consult {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-basis: 260px;
	padding: 10px;
	color: #45474e;
	@apply --r3;
	font-size: 14px;
	text-transform: uppercase;
	background-color: color(#45474e a(15%));
	height: 100%;
	transition: color .3s, background-color .3s;
	margin-left: auto;
}

.site-header__menu .site-header__consult:hover {
	color: #FFF;
	background-color: #c5a054;
}

@media (width <= 1799px) {
	.site-header__menu .site-header__consult {
		margin-left: 15px;
	}
}

@media (width <= 1199px) {
	.site-header__menu .site-header__consult {
		margin-left: auto;
	}
}

@media (width <= 767px) {
	.site-header__menu .site-header__consult {
		order: -1;
		flex-basis: 100%;
		margin-right: auto;
		padding-top: 15px;
		padding-bottom: 15px;
		margin-bottom: 20px;
		font-size: 20px;
	}
}

@media (width <= 1399px) {
	.is-fixed .site-header__consult {
		display: none;
	}
}

@media (width >= 1400px) {
	.is-fixed .site-header__consult {
		display: flex;
		margin-left: 0;
		height: auto;
		flex-basis: 250px;
		background-color: transparent;
		border: 2px solid #c5a054;
		color: #c5a054;
		font-weight: 700;
	}

	.is-fixed .site-header__consult:hover {
		background-color: #C5A054;
		color: white;
	}
}