.blog-inner__wrapper .news-date {
	color: #c5a054;
	@apply --r7;
	font-size: 16px;
	display: block;
	margin-top: 20px;
	margin-bottom: 10px;
}

.blog-inner__wrapper .social-share  p {
	color: #c5a054;
	margin-bottom: 0;
	margin-right: 10px;
}

.view-counter {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	margin-top: 10px;
	display: block;
}

.blog-inner__wrapper #disqus_thread {
	margin-top: 50px;
}

.blog-inner__read-more > h2 {
	color: #0b0c0f;
	@apply --r5;
	font-size: 40px;
	text-align: center;
}

@media (width <= 991px) {
	.blog-inner__read-more .blog__wrapper {
		justify-content: space-around;
	}
}
