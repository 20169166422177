.main-content__product-text {
	margin-bottom: 100px;
}

.main-content__product-text h2 {
	color: #0b0c0f;
	@apply --r4;
	font-family: Roboto;
	font-size: 30px;
	margin-bottom: 40px;
}

@media (width <= 767px) {
	.main-content__product-text h2 {
		text-align: center;
	}
}

.main-content__product-text p {
	color: #0b0c0f;
	@apply --r4;
	font-family: Roboto;
	font-size: 16px;
	margin-bottom: 20px;
}

@media (width >= 1200px) {
	.main-content__product-text-wrapper {
		column-count: 2;
	}
}