.site-header__menu-button {
	background-color: white;
	box-shadow: 0 4px 18px rgba(105, 100, 99, 0.14);
	width: 100%;
}



.navbar-toggle .icon-bar {
	background-color: #45474e;
	margin-left: auto;
	margin-right: auto;
}

@media (width <= 1199px) {
	.site-header__menu-button {
		order: 2;
		margin-top: 15px;
	}
	
	.navbar-toggle {
		width: calc(100% - 100px);
		float: none;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
}