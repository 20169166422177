.page-pagination {
	display: flex;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: 70px;
}

.page-pagination ul {
	display: flex;
	list-style: none;
	padding-left: 0;
}

.page-pagination a {
	color: #0b0c0f;
	@apply --r4;
	font-size: 15px;
	width: 100%;
	text-align: center;
	line-height: 64px;
	transition: color .3s;
}

.pageFill {
	fill: black;
	transition: fill .3s;
}

.page-pagination li {
	background-color: #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 64px;
	height: 64px;
	transition: background-color .3s;
}

.page-pagination li.active:not(.disabled),
.page-pagination li:hover:not(.disabled) {
	background-color: #c5a054;
}

.page-pagination li.active a,
.page-pagination li:hover a {
	color: #FFF;
}

.page-pagination li:not(.disabled) .pageFill {
	fill: #c5a054;
}

.page-pagination .disabled a {
	cursor: default;
}

.page-pagination li:not(.disabled):hover .pageFill {
	fill: white;
}

.page-pagination li:last-child svg {
	transform: rotate(180deg);
}

@media (width <= 479px) {
	.page-pagination li {
		width: 40px;
		height: 40px;
	}

	.page-pagination a {
		line-height: 40px;
	}
	
}