.main-content {
	flex-basis: 100%;
	width: 100%;
}

@media (width >= 768px) {
	.main-content {
		flex-basis: calc(100% - 290px);
		width: calc(100% - 290px);
	}	
}

@media (width >= 1200px) {
	.main-content {
		flex-basis: calc(100% - 350px);
		width: calc(100% - 350px);
	}	
}