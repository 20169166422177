.site-footer__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: 2px solid #c5a054;
}

.site-footer__content {
	flex-basis: calc(100% - 290px);
	max-width: calc(100% - 290px);
	background-color: #FFF;
	padding: 40px 50px 20px;
}

@media (width <= 1399px) {
	.site-footer__content {
		flex-basis: calc(100% - 200px);
		max-width: calc(100% - 200px);
		padding-left: 35px;
		padding-right: 35px;
	}	
}

@media (width >= 1680px) {
	.site-footer__content {
		flex-basis: calc(100% - 350px);
		max-width: calc(100% - 350px);
	}	
}

@media (width <= 1199px) {
	.site-footer__content {
		flex-basis: calc(100% - 30px);
		max-width: calc(100% - 30px);
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (width <= 767px) {
	.site-footer__content {
		padding-left: 0;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}	
}

.site-footer__top {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-bottom: 30px;
	margin-bottom: 40px;
	border-bottom: 1px solid #dcdbd9;
}

.site-footer__soc-and-sub {
	flex-basis: 580px;
	max-width: 580px;
	margin-right: 20px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media (width <= 1799px) {
	.site-footer__soc-and-sub {
		flex-basis: 420px;
		max-width: 420px;
		margin-right: 0;
	}
}

@media (width <= 1199px) {
	.site-footer__soc-and-sub {
		flex-basis: 300px;
		max-width: 300px;
	}
}

@media (width <= 991px) {
	.site-footer__soc-and-sub {
		flex-basis: 100%;
		max-width: 100%;
		margin-top: 40px;
	}	
}

.site-footer__soc-and-sub__wrapper {
	flex-basis: 320px;
	max-width: 320px;
}


@media (width <= 1799px) {
	.site-footer__soc-and-sub__wrapper {
		flex-basis: 100%;
		max-width: 430px;
	}	
}

@media (width <= 991px) {
	.site-footer__soc-and-sub__wrapper {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		text-align: center;
	}
}

.site-footer__bottom {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media (width <= 767px) {
	.site-footer__bottom {
		justify-content: center;
		text-align: center;
	}
}

.site-footer__copy p {
	color: #0b0c0f;
	@apply --r3;
	font-size: 14px;
}

.site-footer__zwebra a {
	color: #b5b9c2;
	@apply --r3;
	font-size: 14px;
}