.sidebar {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
}

@media (width >= 768px) {
	.sidebar {
		position: static;
		flex-basis: 250px;
		width: 250px;
		display: block;
	}	
}

@media (width >= 1200px) {
	.sidebar {
		flex-basis: 280px;
		width: 280px;
		display: block;
	}	
}