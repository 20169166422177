.contacts__request {
	flex-basis: 50%;
	position: relative;
}

@media (width <= 991px) {
	.contacts__request {
		flex-basis: 100%;
	}	
}

.contacts__request-handler {
	box-shadow: 0 4px 18px rgba(105, 100, 99, 0.14);
	background-color: #F5F3F0;
}

@media (width >= 1200px) {
	.contacts__request-handler {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;	
	}
}

.contacts__request h2 {
	margin-top: 0;
	color: #0b0c0f;
	@apply --r5;
	font-size: 21px;
	text-transform: uppercase;
	text-align: center;
	padding: 30px;
	background-color: #FFF;
	text-align: center;
}

.contacts__request form {
	padding: 40px 70px;
}

@media (width <= 767px) {
	.contacts__request form {
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media (width <= 479px) {
	.contacts__request form {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.contacts__request .form-group {
	position: relative;
	margin-bottom: 30px;
}

.contacts__request .form-group input {
	height: 50px;
	animation: order-autofill both .5s;
}

.contacts__request .form-group textarea {
	min-height: 100px;
	animation: order-autofill both .5s;
	resize: vertical;
}

.contacts__request  input,
.contacts__request  textarea {
	color: #0b0c0f;
	background-color: #f5f3f0;
	@apply --r3;
	font-size: 16px;
	padding-left: 0;
	padding-right: 0;
	border-color: #45474e;
}

@keyframes order-autofill {
	to {
		box-shadow: 0 0 0 99px #f5f3f0 inset;
		-webkit-text-fill-color:  #0b0c0f;
	}
}

.contacts__request label {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;
	position: absolute;
	top: -10px;
	left: 0;
	transform: translateY(0);
	transition: transform .2s;
}

.contacts__request .form-control {
	border-radius: 0;
	border-width: 0 0 1px 0;
	transition: border .2s;
}

.contacts__request .form-control:focus {
	outline-color: transparent;
	border-bottom-width: 3px;
	border-color: #c5a054;
	caret-color: #c5a054;
}

.contacts__request .form-control:focus + label {
	transform: translateY(-10px);
}

.contacts__request button {
	width: 250px;
	height: 50px;
	margin-top: 80px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	border: solid 1px #c5a054;
	background-color: #c5a054;
	color: white;
	@apply --r4;
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	transition: color .3s, background-color .3s;
}

.contacts__request button:hover {
	background-color: white;
	color: #c5a054;
}