.index--slider {
	display: none;
}

@media (width >= 1200px) {
	.index--slider {
		display: block;
	}
}

.index--slider__slide {
	padding: 100px 0;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: cover;
}

@media (width >= 1200px) {
	.index--slider__slide {
		height: 580px;
	}
}

.index--slider .index--slider__heading {
	color: white;
	@apply --r7;
	font-size: 81px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.index--slider .index--slider__subheading {
	color: white;
	@apply --r4;
	font-size: 30px;
	margin-bottom: 20px;
}

.index--slider .index--slider__text {
	color: white;
	@apply --r3;
	font-size: 18px;
	font-weight: 300;
}