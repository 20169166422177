.card--details {
	margin-top: 40px;
	margin-bottom: 80px;
	flex-basis: 100%;
}

.card--details .nav-tabs li a,
.card--details .nav-tabs li.active a {
	text-decoration: none;
	color: #6f6f6f;
	@apply --r5;
	font-size: 14px;
	text-transform: uppercase;
	margin-right: 0;
	border-radius: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 4px 0 0 0;
	padding: 15px 40px;
}

.card--details .nav-tabs li:not(:last-child) a {
	border-right: none;
}

.card--details .nav-tabs li.active a {
	font-weight: bold;
	border-color: #c5a054;
	color: #c5a054;
}

.card--details .tab-content {
	padding: 40px 50px;
	background-color: #FFF;
}

.card--details .tab-content h3 {
	color: #0b0c0f;
	@apply --r7;
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 20px;
}

.card--details .tab-content ul {
	margin-bottom: 20px;
	list-style: none;
}

.card--details .tab-content p,
.card--details .tab-content li {
	color: #0b0c0f;
	@apply --r4;
	font-size: 16px;	
}

.card--details .tab-content ul li {
	position: relative;
}

.card--details .tab-content ul li::before {
	content: "";
	width: 6px;
	height: 6px;
	background-color: #c5a054;
	top: 7px;
	left: -16px;
	position: absolute;
	display: block;
	border-radius: 50%;
}

.card--details .nav-tabs {
	border-bottom: none;
}

.card--details .tab-pane {
	overflow-x: auto;
}

@media (width <= 767px) {

	.card--details .tab-content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.card--details .nav-tabs li:not(:last-child) a {
		border: 1px solid #e5e5e5;
		margin-right: -1px;		
	}
	
	.card--details .nav-tabs li:last-child a {
		border: 1px solid #e5e5e5;
		margin-bottom: 1px;
	}


	.card--details .nav-tabs .active a,
	.card--details .nav-tabs li:hover a {
		border-top: 1px solid #c5a054;
	}
}