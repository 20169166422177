.site-footer__tel-and-email {
	flex-basis: 220px;
}

.site-footer__tel-and-email a {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

@media (width <= 1799px) {
	.site-footer__tel-and-email {
		order: -1;
		flex-basis: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.site-footer__tel-and-email a:first-child {
		margin-right: 40px;
	}
}

@media (width <= 1199px) {
	.site-footer__tel-and-email a {
		margin-bottom: 10px;
	}
}

@media (width <= 991px) {
	.site-footer__tel-and-email {
		justify-content: center;
		margin-bottom: 20px;
		margin-left: 20px;
		margin-right: 20px;
	}

	.site-footer__tel-and-email a:first-child {
		margin-right: 20px;
		margin-left: 20px;
	}
}

.site-footer__tel-and-email span {
	margin-left: 10px;
	color: #010104;
	@apply --r4;
	font-size: 16px;
	transition: color .3s;
}

@media (width <= 1199px) {
	.site-footer__tel-and-email span {
		font-size: 20px;
	}
}

@media (width <= 767px) {
	.site-footer__tel-and-email span {
		margin-left: 10px;
		margin-right: 20px;
		font-size: 20px;
	}	
}

.site-footer__tel-and-email .aboutGold {
	fill: #c49f53;
	transition: fill .3s;
}

.site-footer__tel-and-email a:hover span {
	color: #dedfd7;
}

.site-footer__tel-and-email a:hover .aboutGold {
	fill: #dedfd7;
}